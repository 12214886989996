$spacing-list:(
  default: 24px,
  xs: $line-height-computed-xs,
  sm: $line-height-computed-sm,
  smd: $line-height-computed-md /2,
  md: $line-height-computed-md,
  lg: $line-height-computed-lg,
);

$direction-list:(
  top: 't',
  right: 'r',
  bottom: 'b',
  left: 'l',
);

.u-nomg {
  margin: 0;
}

.u-nopd {
  padding: 0;
}

@each $spacing-key, $spacing-value in $spacing-list {
  .u-gap-#{$spacing-key} {
    gap: $spacing-value;
  }
}

// Génère les classes u-mgX et u-pdX
@each $direction-key, $direction-value in $direction-list {
  .u-nomg#{$direction-value} {
    margin-#{$direction-key}: 0;
  }

  .u-nopd#{$direction-value} {
    padding-#{$direction-key}: 0;
  }

  @each $spacing-key, $spacing-value in $spacing-list {
    @if $spacing-key == 'default' {
      .u-mg#{$direction-value} {
        margin-#{$direction-key}: $spacing-value;
      }
      .u-pd#{$direction-value} {
        padding-#{$direction-key}: $spacing-value;
      }

      .u-mg {
        margin: $spacing-value;
      }
      .u-pd {
        padding: $spacing-value;
      }
    }
    @else {
      .u-mg#{$direction-value}-#{$spacing-key} {
        margin-#{$direction-key}: $spacing-value;
      }
      .u-pd#{$direction-value}-#{$spacing-key} {
        padding-#{$direction-key}: $spacing-value;
      }

      .u-mg-#{$spacing-key} {
        margin: $spacing-value;
      }
      .u-pd-#{$spacing-key} {
        padding: $spacing-value;
      }
    }
  }
}

.u-text-uppercase {
  text-transform: uppercase;
}

.u-ws-pre {
  white-space: pre;
}

.u-bordered-table {
  border: 1px solid $body-color;
  border-collapse: collapse;

  & tr,
  & th,
  & td {
    border: 1px solid $body-color;
    border-collapse: collapse;
    padding: ($line-height-computed / 4) ($line-height-computed / 2);
    vertical-align: top;
  }
}

.d-flex {
  display: flex;

  &.mod-column {
    flex-direction: column;
  }
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}
