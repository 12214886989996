.task-modal {
  background-color: #FFFFFF;
  border: solid 1px #D7D7D7;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(51, 51, 51, 0.09);
  position: relative;
  width: 750px;

  &.mod-project-template {
    display: grid;
    grid-template-columns: 100%;
    position: relative;
    width: 600px;
  }

  &:not(.mod-project-template) {
    @media (min-width: $screen-md) {
      width: 90%;
    }
    @media (min-width: $screen-lg) {
      width: 70%;
    }
    @media (min-width: $screen-xlg) {
      width: 60%;
    }
  }
}

.task-modal-main-container {
  overflow-y: auto;
  max-height: 80vh;
  min-width: 450px;
}

/* ------------ */
/* Close button */
/* ------------ */

.task-modal-close {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: (24 / 12);
  margin: 0;
  padding: $line-height-computed-xs $grid-gutter-width-xs;
  position: absolute;
  right: $grid-gutter-width-sm;
  top: $line-height-computed-sm;
  transition: 200ms ease-in-out background-color;
  z-index: 1;

  &:hover, &:focus, &:active {
    background-color: #ffffff;
  }
}

.task-modal-close-icon {
  color: $blue-grey;
  display: block;
  font-size: 32px;
  transition: 200ms ease-in-out color;

  .task-modal-close:hover &,
  .task-modal-close:focus & {
    color: $body-color;
  }
}

/* ------ */
/* Loader */
/* ------ */

.task-modal-loader {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  bottom: 1px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 1px;
  position: absolute;
  right: 1px;
  top: 1px;
  z-index: 10;
}

/* -------- */
/* Dropzone */
/* -------- */

.task-modal-dropzone {

}

.task-modal-dropzone-inner {
  display: grid;
  grid-template-columns: 70% 30%;
  position: relative;

  &.mod-full {
    grid-template-columns: 100%;

    .task-modal-close {
      right: $grid-gutter-width;
      &:hover {
        background-color: #f3f4f6;
      }
    }

    .task-modal-header {
      padding-right: $grid-gutter-width * 3;
    }
  }
}

.task-modal-dropzone-overlay {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  border: 2px dashed $brand-color-cyan;
  border-radius: 8px;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}

.task-modal-dropzone-overlay-inner {
  color: $brand-color-cyan;
  flex: 1 1 100%;
  font-size: 24px;
  font-weight: 500;
  line-height: ($line-height-computed-md / 24px);
  text-align: center;
}

.task-modal-dropzone-overlay-inner-icon {
  display: block;
  font-size: $line-height-computed-md;
}

/* ------ */
/* Header */
/* ------ */

.task-modal-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0;
  padding: $line-height-computed-sm $grid-gutter-width 0 ($grid-gutter-width-sm + $grid-gutter-width-xs);

  .task-modal.mod-project-template & {
    padding-right: $grid-gutter-width-md + $grid-gutter-width-sm + $grid-gutter-width-xs;
  }
}

.task-modal-header-milestone {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: $blue-grey;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 0 1 auto;
  margin: $line-height-computed-xs auto $line-height-computed-xs 0;
  min-width: 200px;
  padding: $line-height-computed-xs $grid-gutter-width-xs;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out color;

  &:not([disabled]):not(.is-loading):not(.is-static):hover,
  &:not([disabled]):not(.is-loading):not(.is-static):focus {
    background-color: rgba(0, 135, 255, 0.05);
    color: $body-color;
  }

  &.is-loading {
    cursor: wait;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &.is-static {
    cursor: inherit;
  }
}

.task-modal-header-milestone-icon {
  margin-right: $grid-gutter-width-xs;
}

.task-modal-header-milestone-text {
  font-family: inherit;
  font-size: $font-size;
  font-weight: inherit;
  line-height: $line-height;
  overflow: hidden;
  text-decoration: underline;
  text-overflow: ellipsis;
  white-space: nowrap;

  .task-modal-header-milestone.is-static & {
    text-decoration: none;
  }
}

.task-modal-header-info,
.task-modal-header-menu,
.task-modal-section-button {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: $blue-grey;
  cursor: pointer;
  display: block;
  flex: 0 0 36px;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0 0 0 $grid-gutter-width-xs;
  padding: $line-height-computed-xs $grid-gutter-width-xs;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out color;

  &:hover, &:focus {
    background-color: $sidebar-hover-color;
    color: $body-color;
  }
}

.task-modal-header-info-icon,
.task-modal-header-menu-icon,
.task-modal-section-button-icon, {
  display: block;
}

/* ---------------- */
/* Checkbox & Title */
/* ---------------- */

.task-modal-title {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0 $grid-gutter-width $line-height-computed-sm ($grid-gutter-width-sm + $grid-gutter-width-xs);

  .task-modal.mod-project-template & {
    padding-bottom: 0;
  }
}

.task-modal-title-checkbox {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  flex: 0 0 auto;
  margin: 0 $grid-gutter-width-xs 0 0;
  overflow: hidden;
  padding: $line-height-computed-xs $grid-gutter-width-xs;
  transition: 200ms ease-in-out background-color;

  &:not([disabled]):not(.is-loading):not(.is-static):hover,
  &:not([disabled]):not(.is-loading):not(.is-static):focus {
    background-color: $sidebar-hover-color;
  }

  &.is-loading {
    cursor: wait;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &.is-static {
    cursor: inherit;
  }
}

.task-modal-title-checkbox-icon {
  background-color: #ffffff;
  border: 2px solid $brand-color-cyan;
  border-radius: 50%;
  color: $blue-grey;
  display: block;
  font-size: 20px;
  height: 24px;
  line-height: (24/22);
  width: 24px;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out border-color,
              200ms ease-in-out color;

  .task-modal-title-checkbox:not([disabled]):not(.is-loading):not(.is-static):not(.is-checked):hover &,
  .task-modal-title-checkbox:not([disabled]):not(.is-loading):not(.is-static):not(.is-checked):focus & {
    background-color: $brand-color-cyan;
    border-color: $brand-color-cyan;
    color: #ffffff;
  }

  .task-modal-title-checkbox.is-checked & {
    background-color: $color-success;
    border-color: $color-success;
    color: #ffffff;
  }
}

.task-modal-title-input-container {
  flex: 1 1 auto;
  margin: 0;
  padding: 0;
}

.task-modal-title-input {
  background-color: #ffffff;
  border: none;
  border-radius: 4px;
  color: $body-color;
  display: block;
  font-family: inherit;
  font-size: 24px;
  font-weight: 400;
  height: (2 * $font-size) + (2 * $line-height-computed-xs);
  line-height: 2 * $font-size;
  margin: 0;
  padding: $line-height-computed-xs $grid-gutter-width-xs;
  resize: none !important;
  text-align: left;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out color;
  width: 100%;

  .task-modal.mod-project-template & {
    padding-left: $grid-gutter-width-sm;
    padding-right: $grid-gutter-width-sm;
  }

  &:not([disabled]):not(.is-loading):not(.is-static):hover,
  &:not([disabled]):not(.is-loading):not(.is-static):focus {
    background-color: rgba(0, 135, 255, 0.05);
  }

  &[disabled],
  &.is-loading {
    background-color: rgba(81, 101, 132, 0.03);
    color: rgba(81, 101, 132, 0.5);
    cursor: not-allowed;
  }

  &.is-loading {
    cursor: wait;
  }

  &.is-static {
    cursor: inherit;
    height: auto;
  }
}

/* ------------------------- */
/* User in charge & due date */
/* ------------------------- */

.task-modal-meta {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 0 $line-height-computed 0;
  padding: 0 $grid-gutter-width 0 ($grid-gutter-width-sm + $grid-gutter-width-xs);
}

.task-modal-meta-user-in-charge,
.task-modal-meta-due-date {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  color: $blue-grey;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex: 0 1 auto;
  margin: 0 $grid-gutter-width-xs 0 0;
  padding: 0 $grid-gutter-width-xs;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out color;

  &:not([disabled]):not(.is-loading):not(.is-static):hover,
  &:not([disabled]):not(.is-loading):not(.is-static):focus {
    background-color: rgba(0, 135, 255, 0.05);
    color: $body-color;
  }

  &.is-loading {
    cursor: wait;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &.is-static {
    cursor: inherit;
  }
}

.task-modal-meta-user-in-charge-initials {
  background-color: $sidebar-border-color;
  border: 1px solid #ffffff;
  border-radius: 50%;
  flex: 0 0 auto;
  font-family: inherit;
  height: 24px;
  margin: 0 $grid-gutter-width-xs 0 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 24px;
}

.task-modal-meta-user-in-charge-initials-text {
  border-radius: 50%;
  bottom: 0;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  left: 0;
  line-height: (22 / 12);
  overflow: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  text-decoration: none;
  top: 0;
  z-index: 1;
}

.task-modal-meta-user-in-charge-icon {
  margin: 0 $grid-gutter-width-xs 0 0;
}

.task-modal-meta-user-in-charge-name,
.task-modal-meta-due-date-text {
  flex: 1 1 auto;
  font-size: $font-size;
  line-height: $line-height;
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  text-decoration: underline;
  white-space: nowrap;

  .task-modal-meta-due-date.is-static &,
  .task-modal-meta-user-in-charge.is-static & {
    text-decoration: none;
  }
}

.task-modal-meta-due-date-icon {
  margin-right: $grid-gutter-width-xs;
}

/* ------- */
/* Section */
/* ------- */

.task-modal-section {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: $line-height-computed 0;
  padding: 0 $grid-gutter-width;
}

.task-modal-section-title {
  color: $body-color;
  font-size: 16px;
  font-weight: 700;
  line-height: (24 / 16);
  margin: 0 $grid-gutter-width-xs 0 0;
  padding: 0;
}

.task-modal-section-action {
  background-color: #ffffff;
  border: solid 1px $brand-color-cyan;
  border-radius: 4px;
  color: $brand-color-cyan;
  cursor: pointer;
  font-family: inherit;
  font-size: 12px;
  font-weight: 400;
  line-height: (22 / 12);
  margin: 0 0 0 $grid-gutter-width-sm;
  padding: 0 $grid-gutter-width-xs;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out border-color,
              200ms ease-in-out color;

  &:not([disabled]):not(.is-loading):hover,
  &:not([disabled]):not(.is-loading):focus {
    background-color: lighten($brand-color-cyan, 5%);
    border-color: lighten($brand-color-cyan, 5%);
    color: #ffffff;
  }

  &.is-loading {
    cursor: wait;
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

.task-modal-section-button {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: $blue-grey;
  cursor: pointer;
  display: block;
  flex: 0 0 36px;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0 0 0 $grid-gutter-width-xs;
  padding: $line-height-computed-xs $grid-gutter-width-xs;
  transition: 200ms ease-in-out background-color,
  200ms ease-in-out color;

  &:hover, &:focus {
    background-color: $sidebar-hover-color;
    color: $body-color;
  }
}


/* ----------- */
/* Description */
/* ----------- */

.task-modal-description-input-container {
  .task-modal.mod-project-template & {
    padding-bottom: $line-height-computed;
  }
}

.task-modal-description-input {
  background-color: #ffffff;
  border: solid 1px $light-grey-2;
  border-radius: 8px;
  color: $body-color;
  display: block;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: $line-height;
  overflow-y: scroll;
  margin: 0 $grid-gutter-width;
  padding: $line-height-computed-sm $grid-gutter-width-sm;
  max-height: 175px;
  width: calc(100% - #{$grid-gutter-width * 2});
  text-align: left;
  transition: 200ms ease-in-out background-color,
              200ms ease-in-out border-color,
              200ms ease-in-out color;

  &:not([disabled]):not(.is-loading):not(.is-static):hover,
  &:not([disabled]):not(.is-loading):not(.is-static):focus {
    background-color: rgba(0, 135, 255, 0.05);
    border-color: transparent;
  }

  &[disabled],
  &.is-loading {
    background-color: rgba(81, 101, 132, 0.03);
    border-color: transparent;
    color: rgba(81, 101, 132, 0.5);
    cursor: not-allowed;
  }

  &.is-loading {
    cursor: wait;
  }

  &.is-static {
    border-color: transparent;
    color: $blue-grey;
    cursor: inherit;
    padding: 0 0 0 $grid-gutter-width-sm;
    overflow-y: auto;
  }

  &.is-hidden {
    display: none;
  }

  &.mod-button {
    cursor: pointer;
    overflow-y: auto;
  }
}

.task-modal-description-input > a {
  color: $brand-color-cyan;
  transition: 200ms ease-in-out color;

  &:hover, &:focus, &:active {
    color: lighten($brand-color-cyan, 10%);
  }
}

/* -------------------------- */
/* Custom field - List dialog */
/* -------------------------- */

.task-modal-custom-field-list-dialog {
  max-width: 270px;
}

.task-modal-custom-field-list-dialog-list {
  border-bottom: solid 1px #D7D7D7;
  margin: 0 0 $line-height-computed-xs;
  max-height: 170px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: $line-height-computed-xs 0;
}

.task-modal-custom-field-list-dialog-list-item {
  align-items: center;
  background-color: transparent;
  border: none;
  color: $body-color;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: $line-height-computed-xs $grid-gutter-width-sm;
  text-align: left;
  transition: 200ms ease-in-out background-color;
  width: 100%;

  &.is-selected {
    font-weight: 700;
  }

  &.is-loading {
    cursor: wait;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  &:not([disabled]):not(.is-loading):hover,
  &:not([disabled]):not(.is-loading):focus {
    background-color: $body-bg;
  }
}

.task-modal-custom-field-list-dialog-list-item-color {
  background-color: transparent;
  border-radius: $border-radius;
  height: $line-height-computed;
  margin: 0 $grid-gutter-width-xs 0 0;
  width: $grid-gutter-width;
}

.task-modal-custom-field-list-dialog-list-item-text {
  flex: 1 1 auto;
  margin: 0 auto 0 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.task-modal-custom-field-list-dialog-list-item-icon {
  flex: 0 0 auto;
  transition: 200ms ease-in-out opacity;

  &.mod-add {
    color: $color-success;
    opacity: 0;
  }

  .task-modal-custom-field-list-dialog-list-item.is-selected & {
    color: $brand-color-cyan;
  }

  .task-modal-custom-field-list-dialog-list-item:hover &.mod-add,
  .task-modal-custom-field-list-dialog-list-item:focus &.mod-add {
    opacity: 1;
  }
}
