.btn {
  background-color: $body-color;
  border: none;
  border-radius: $border-radius;
  color: $body-bg;
  cursor: pointer;
  display: inline-block;
  font-family: $font-family;
  font-size: $font-size;
  line-height: $line-height-computed;
  margin: 0;
  padding: ($line-height-computed / 4) $grid-gutter-width-sm;
  text-align: center;

  &:hover, &:focus {
    background-color: $body-bg;
    color: $body-color;
  }

  &.mod-invert {
    background-color: $body-bg;
    color: $body-color;
  }
  &.mod-invert:hover, &.mod-invert:focus {
    background-color: $body-color;
    color: $body-bg;
  }

  &.mod-white {
    background-color: #FFFFFF;
    color: $blue-grey;
    text-decoration: none;

    &:hover, &:focus {
      background-color: $blue-grey;
      color: #FFFFFF;
    }
  }

  &.mod-alert {
    background-color: $color-alert;
    color: #ffffff;
  }
  &.mod-alert:hover, &.mod-alert:focus {
    background-color: desaturate(darken($color-alert, 10%), 25%);
    color: #ffffff;
  }

  &:disabled, &.mod-invert:disabled, &.mod-alert:disabled {
    background-color: $light-grey;
    color: $dark-grey;
    cursor: not-allowed;
  }

  &.mod-link {
    background-color: transparent;
    border: none;
    color: $body-bg;
    cursor: pointer;
    display: inline;
    font-size: $font-size;
    line-height: $line-height-computed;
    margin: 0;
    padding: 0;
    text-align: left;
    text-decoration: underline;

    &:hover, &:focus {
      background-color: transparent;
      color: $body-color;
    }
  }
}
