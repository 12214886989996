.accordion{
  display: flex;
  flex-flow: column nowrap;
  background: #fff;
  &-item{
    flex: 1;
    cursor: pointer;
    padding: $line-height-computed-sm $grid-gutter-width $line-height-computed-sm $grid-gutter-width/2;

    &-header{
      display: flex;
      align-items: center;

      &-label{
        flex: 0 1 100%;
        color: $link-color;
        text-transform: uppercase;
      }

      &-icon{
        flex: 0 0 auto;
      }
    }

    &-content{
      color: $body-color;
      position: relative;
    }
  }
}
