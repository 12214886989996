.large-form {
  margin: $line-height-computed $grid-gutter-width;

  @media screen and (min-width: $screen-md) {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
  }
}

  .large-form-heading {
    color: $sidebar-color;
    font-size: 18px;
    line-height: $line-height-computed / 18px;
    margin: 0;
    padding: $line-height-computed-sm $grid-gutter-width-sm;
  }

  .large-form-heading-description {
    color: $sidebar-color;
    font-size: 12px;
    margin: 0;
    margin-top: -$line-height-computed-sm;
    padding: $line-height-computed-sm $grid-gutter-width-sm;

    & > a {
      color: inherit;
      text-decoration: underline;

      &:hover, &:active {
        color: $link-color;
      }
    }
  }

  .large-form-row {
    background-color: $grey;
    display: flex;
    margin: 0;
    padding: 0;

    &:not(:first-child) &-input {
      border-top: solid 1px $body-bg;
    }
    &:not(:last-child) &-input {
      border-bottom: solid 1px $body-bg;
    }

    &-label {
      display: flex;
      align-items: center;
      flex: 1 1 20%;
      margin: 0;
      min-width: 200px;
      padding: $line-height-computed-sm $grid-gutter-width-sm;

      &[for] {
        cursor: pointer;
      }
    }

    &-input {
      background-color: #ffffff;
      border: none;
      border-left: solid 2px $body-bg;
      color: $body-color;
      flex: 1 1 80%;
      padding: $line-height-computed-sm $grid-gutter-width;

      &[readonly] {
        cursor: not-allowed;
      }

      &.mod-avatar-select {
        padding: 0;
      }

      &.mod-color-picker {
        & > .sp-replacer { // conteneur de la preview
          background-color: #ffffff;
          border: none;
          display: block;
          margin: 0;
          padding: 0;
          position: relative;

          &:hover, &:focus, &.sp-active {
            border-color: $link-color;
          }

          .sp-preview { // bloc de couleur pour preview
            border: none;
            border-radius: $border-radius;
            height: $line-height-computed;
            margin: 0;
            padding: 0;
            width: $grid-gutter-width-md;

            .sp-preview-inner {
              border-radius: $border-radius;

              &:after {
                left: $grid-gutter-width-sm;
              }
            }
          }
        }
      }
    }

    &-error {
      flex-grow: 1;
      background-color: lighten($color-alert, 35%);
      color: darken($color-alert, 30%);
      margin: 0;
      padding: 1em;
    }

    &-help {
      color: $sidebar-color;
      font-size: 0.857142857em;
      line-height: 2em;
      margin: 0;
      padding: 1em;
    }
  }
