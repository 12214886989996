.members-popover {
  background-color: #FFFFFF;
  border: solid 1px #D7D7D7;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(51, 51, 51, 0.09);
  min-width: 0;
}

.members-popover-title {
  border-bottom: solid 1px #D7D7D7;
  color: #AAAAAA;
  display: block;
  font-size: 12px;
  font-weight: 700;
  height: $line-height-computed-md;
  line-height: ($line-height-computed / 12px);
  margin: 0;
  overflow: hidden;
  padding: $line-height-computed-sm $grid-gutter-width ($line-height-computed-sm - 1px) 16px;
  text-transform: uppercase;
}

.members-popover-list {
  list-style: none;
  margin: 0;
  max-height: 50vh;
  overflow-y: auto;
  padding: $line-height-computed-xs 0;
}

.members-popover-list-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 8px $grid-gutter-width-sm;
}

.members-popover-list-item-initials {
  background-color: $sidebar-border-color;
  border: 2px solid #ffffff;
  border-radius: 50%;
  font-family: inherit;
  height: 32px;
  margin: 0 $grid-gutter-width-xs 0 0;
  overflow: hidden;
  padding: 0;
  text-align: center;
  text-decoration: none;
  width: 32px;
}

.members-popover-list-item-initials-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: (28 / 12);
}

.members-popover-list-item-name {
  font-weight: 700;
}

.members-popover-list-item-role {
  font-weight: 400;
  position: relative;

  &:before {
    content: "—";
    padding: 0 4px;
    position: relative;
  }
}
