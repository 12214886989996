.project-nav {
  align-items: center;
  background-color: #ffffff;
  border: none;
  border-top: 1px solid #EAEAEA;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 $line-height-computed 0;
  padding: 9px 0 10px 0;
}

.project-nav-item {
  align-items: center;
  background-color: #F6F6F6;
  border: none;
  border-radius: 4px;
  color: #7F7F7F;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: (24 / 14);
  margin: 0;
  padding: 2px 8px;
  text-decoration: none;
  transition:
    200ms ease-in-out color,
    200ms ease-in-out background-color;

  &:not(:first-child) {
    margin-left: 8px;
  }

  &:hover, &:active {
    color: #ffffff;
  }

  &.is-active {
    background-color: $brand-color-cyan;
    color: #ffffff;
    font-weight: 700;

    &:hover, &:active {
      text-decoration: underline;
    }
  }

  &.is-disabled {
    cursor: not-allowed;

    &.is-disabled:hover{
      background-color: #f6f6f6;
      color: #7f7f7f;
    }
  }
}

.project-nav-item-icon {
  &.material-icons {
    font-size: 16px;
    margin-right: 4px;
  }
}
