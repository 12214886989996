.task-modal-attachment-list {
  display: grid;
  grid-template-rows: auto auto; // crée jusqu'à 2 lignes si besoin
  grid-auto-flow: column; // ajoute des colonnes si besoin
  grid-gap: $line-height-computed-sm $grid-gutter-width;
  list-style: none;
  margin: 0 $grid-gutter-width-xs 0 $grid-gutter-width;
  overflow-x: auto;
  padding: 0 $grid-gutter-width-sm $line-height-computed-sm 0;
  scroll-snap-type: x mandatory;

  &.mod-no-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-x: hidden;
    padding: 0 ($grid-gutter-width-sm + $grid-gutter-width-xs) $line-height-computed 0;
  }

  &.mod-empty {
    color: $blue-grey;
    padding-left: $grid-gutter-width-sm;
    padding-right: $grid-gutter-width-sm;
  }
}

.task-modal-attachment-list:not(.mod-no-content):not(.mod-empty):after {
  // Permet de forcer l'affichage de la marge à droite, lors du scroll
  content: '';
  width: 1px;
}

.task-modal-attachment-list-empty {
  background-color: #F3F4F6;
  border: solid 1px $light-grey-2;
  border-radius: 8px;
  color: $body-color;
  flex: 1 1 100%;
  margin: auto 0;
  padding: $line-height-computed $grid-gutter-width;
  text-align: center;
  width: 100%;

  &.mod-dropzone {
    cursor: pointer;
  }
}

.task-modal-attachment-list-empty-link {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-decoration: underline;
}

.task-modal-attachment-list-item {
  align-items: center;
  background-color: #F9FAFB;
  border: solid 1px $light-grey-2;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0;
  overflow: hidden;
  padding: $line-height-computed-xs $grid-gutter-width-sm;
  position: relative;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  width: 380px;

  &.is-clickable {
    cursor: pointer;
  }
}

.task-modal-attachment-list-item-loader {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  bottom: 0;
  cursor: wait;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

.task-modal-attachment-list-item-menu {
  background-color: transparent;
  border: none;
  border-radius: 8px;
  color: $blue-grey;
  cursor: pointer;
  display: block;
  flex: 0 0 36px;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: $line-height-computed-xs $grid-gutter-width-xs;
  position: absolute;
  right: 0;
  top: 0;
  transition: 200ms ease-in-out background-color,
  200ms ease-in-out color;
  z-index: 1;

  .task-modal-attachment-list-item:not(:hover) & {
    display: none;
  }

  &:hover, &:focus {
    background-color: $sidebar-hover-color;
    color: $body-color;
  }
}

.task-modal-attachment-list-item-menu-icon {
  display: block;
}

.task-modal-attachment-list-item-extension {
  background-color: $brand-color-cyan;
  border-radius: 4px;
  color: #ffffff;
  flex: 0 0 ($grid-gutter-width-md + $grid-gutter-width-xs);
  font-size: $font-size;
  font-weight: 700;
  line-height: $line-height;
  margin: 0 $grid-gutter-width-sm 0 0;
  overflow: hidden;
  padding: $line-height-computed-sm $grid-gutter-width-xs;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

.task-modal-attachment-list-item-content {
  flex: 1 1 auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.task-modal-attachment-list-item-title {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  color: $blue-grey;
  font-family: inherit;
  font-size: $font-size;
  font-weight: 700;
  line-height: $line-height;
  margin: 0;
  padding: 0 $grid-gutter-width-xs;
  text-align: left;
  transition: 200ms ease-in-out background-color,
  200ms ease-in-out color;
  width: calc(100% - 24px);

  &:not([disabled]):not(.is-loading):not(.is-static):hover,
  &:not([disabled]):not(.is-loading):not(.is-static):focus {
    background-color: rgba(0, 135, 255, 0.05);
    color: $body-color;
  }

  &[disabled],
  &.is-loading {
    background-color: rgba(81, 101, 132, 0.03);
    color: rgba(81, 101, 132, 0.5);
    cursor: not-allowed;
  }

  &.is-loading {
    cursor: wait;
  }
}

.task-modal-attachment-list-item-meta {
  color: $blue-grey;
  font-size: 12px;
  font-weight: 400;
  line-height: ($line-height-computed / 12px);
  margin: 0;
  padding: 0 $grid-gutter-width-xs;
}

.task-modal-attachment-list-item-progress,
.task-modal-attachment-list-item-progress-bar {
  background-color: $light-grey-2;
  border-radius: 3px;
  height: 6px;
  margin: 8px $grid-gutter-width-xs;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.task-modal-attachment-list-item-progress-bar {
  background-color: $brand-color-cyan;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}
